import React, {useEffect, useRef} from "react";
import {LottieRefCurrentProps} from "lottie-react";
import {Assets} from "../../assets/assets";
import {useTranslation} from "../../utilities/i18nUtils";
import Icon from "@mdi/react";
import {mdiInformationVariant} from "@mdi/js";
import {DagPlanningRestModel, DagPlanningRestModelStatusEnum} from "../../_generated/field-service-be-openapi";
import {Config} from "../../utilities/config";
import {PlanningInfoBox} from "./PlanningInfoBox";
import {PlanningUtils} from "../../utilities/planningUtils";
import {Trans} from "react-i18next";
import { Afstand } from "components/Afstand";

export interface PlanningLeegProps {
    dagPlanning?: DagPlanningRestModel;
}

export const PlanningLeeg: React.FC<PlanningLeegProps> = React.memo((props) => {
    const {dagPlanning} = props;

    const {t} = useTranslation("planning");

    const ref = useRef<LottieRefCurrentProps | null>();

    useEffect(() => {
        if (ref.current) {
            ref.current.setSpeed(0.8);

            setTimeout(() => ref.current?.play(), 1000);
        }
    }, [ref]);

    const message = dagPlanning ? Config.categoryLabels.planningLeegTekst[dagPlanning.status] : "";

    const totaleAfstand = PlanningUtils.getTotaleAfstandInMeter(dagPlanning);

    return (
        <PlanningInfoBox variant="info" icon={<Icon path={mdiInformationVariant} size={2.6}/>}>
            <p className="display-6">{t("planning:PlanningLeeg.niets-gepland-vandaag", "Er staat niets gepland vandaag")}</p>

            {dagPlanning?.status !== DagPlanningRestModelStatusEnum.Gearchiveerd && <p className="text-muted">{message}</p>}
            {dagPlanning?.status === DagPlanningRestModelStatusEnum.Gearchiveerd && (
                <p className="text-muted">
                    <Trans i18nKey="planning:PlanningLeeg.op-deze-gearchiveerde-planning-is-er-x-km-gepland">
                        Op deze gearchiveerde dag is er <Afstand afstand={totaleAfstand}/> gepland.
                    </Trans>
                </p>
            )}

            <div style={{width: "20%"}} className="mb-4">
                <img src={Assets.emptyFolder} className="img-fluid" alt="Leeg"/>
            </div>
        </PlanningInfoBox>
    );
});
