import React from "react";
import {MDBContainer, MDBNavbar, MDBNavbarItem, MDBNavbarNav, MDBRipple} from "mdb-react-ui-kit";
import {NavLink} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiArchiveOutline, mdiCalendar, mdiFlagVariantOutline, mdiMapMarkerOutline, mdiQrcodeScan} from "@mdi/js";
import {useHuidigToestel} from "../redux/slices/toestellen/hooks";
import {useHuidigeBezoekSessie} from "../redux/slices/bezoeksessie/hooks";
import {useTranslation} from "../utilities/i18nUtils";

export const Footer: React.FC = () => {
    const huidigeBezoekSessie = useHuidigeBezoekSessie();
    const huidigToestel = useHuidigToestel();
    const {t} = useTranslation("algemeen");

    return (
        <>
            <MDBNavbar bgColor="white" color="primary" expand className="bottom-navbar fixed-bottom py-0 shadow-top">
                <MDBContainer fluid>
                    <MDBNavbarNav className="flex-row">
                        <MDBNavbarItem className="flex-even">
                            <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                                <NavLink exact to="/" className="nav-link d-flex flex-column align-items-center">
                                    <Icon path={mdiCalendar} size={1}/>

                                    <span className="font-smaller font-weight-semibold">{t("Footer.planning", "Planning")}</span>
                                </NavLink>
                            </MDBRipple>
                        </MDBNavbarItem>
                        <MDBNavbarItem className="flex-even">
                            <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                                <NavLink exact to={huidigeBezoekSessie ? `/bezoek/${huidigeBezoekSessie.id}` : "/disabled"}
                                         className={`nav-link d-flex flex-column align-items-center ${!huidigeBezoekSessie ? "disabled" : ""}`}>
                                    <Icon path={mdiMapMarkerOutline} size={1}/>

                                    <span className="font-smaller font-weight-semibold">{t("Footer.huidig-adres", "Huidig adres")}</span>
                                </NavLink>
                            </MDBRipple>
                        </MDBNavbarItem>
                        <MDBNavbarItem className="flex-even">
                            <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                                <NavLink exact
                                         to={huidigeBezoekSessie ? `/bezoek/taken/${huidigeBezoekSessie.id}` : "/disabled"}
                                         className={`nav-link d-flex flex-column align-items-center ${!huidigeBezoekSessie ? "disabled" : ""}`}>
                                    <Icon path={mdiArchiveOutline} size={1}/>

                                    <span className="font-smaller font-weight-semibold">{t("Footer.taken", "Taken")}</span>
                                </NavLink>
                            </MDBRipple>
                        </MDBNavbarItem>
                        {huidigToestel && (
                            <MDBNavbarItem className="flex-even">
                                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                                    <NavLink exact
                                             to={huidigToestel && huidigeBezoekSessie ? `/bezoek/taken/${huidigeBezoekSessie.id}/toestel/${huidigToestel.informatie?.id}` : ""}
                                             className={`nav-link d-flex flex-column align-items-center ${!huidigToestel ? "disabled" : ""}`}>
                                        <Icon path={mdiFlagVariantOutline} size={1}/>

                                        <span className="font-smaller font-weight-semibold">{t("Footer.huidig-toestel", "Huidig toestel")}</span>
                                    </NavLink>
                                </MDBRipple>
                            </MDBNavbarItem>
                        )}
                        {!huidigToestel && (
                            <MDBNavbarItem className="flex-even">
                                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                                    <NavLink exact
                                             to="/zoek-toestel"
                                             className="nav-link d-flex flex-column align-items-center">
                                        <Icon path={mdiQrcodeScan} size={1}/>

                                        <span className="font-smaller font-weight-semibold">{t("Footer.scan-toestel", "Scan toestel")}</span>
                                    </NavLink>
                                </MDBRipple>
                            </MDBNavbarItem>
                        )}
                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
};
