import React, {useCallback, useEffect, useMemo, useState} from "react";
import {DirectionsRenderer, GoogleMap, OverlayView} from "@react-google-maps/api";
import {
    AbstractDagPlanningEntryRestModel,
    CoordinaatRestModel,
    CoordinaatRestModelTypeEnum,
    DagPlanningBezoekEntryRestModel,
    DagPlanningRestModel,
    DagPlanningRestModelStatusEnum,
    DagPlanningStopRestModel,
    DagPlanningStopRestModelTypeEnum
} from "../../_generated/field-service-be-openapi";
import {useServiceAdressen} from "../../redux/slices/serviceAdressen/hooks";
import {DagPlanningEntryMarker} from "./markers/DagPlanningEntryMarker";
import {VertrekMarker} from "./markers/VertrekMarker";
import {BestemmingMarker} from "./markers/BestemmingMarker";
import {useDeepCompareEffect, useDeepCompareMemo} from "use-deep-compare";

const directionsCache: Record<string, google.maps.DirectionsResult> = {};

export interface HomePageMapProps {
    dagPlanning?: DagPlanningRestModel;

    onMarkerClick?(entry: AbstractDagPlanningEntryRestModel): void;
}

export const HomePageMap: React.FC<HomePageMapProps> = (props) => {
    const {dagPlanning, onMarkerClick} = props;

    const [map, setMap] = useState<google.maps.Map>();
    const [mapIdle, setMapIdle] = useState(false);
    const [mapIdleAfterDirectionsBoundSet, setMapIdleAfterDirectionsBoundSet] = useState(false);

    const [directions, setDirections] = useState<google.maps.DirectionsResult | undefined>(dagPlanning ? directionsCache[dagPlanning.datum] : undefined);
    const [directionsLoading, setDirectionsLoading] = useState(false);
    const [directionsBoundsSet, setDirectionsBoundsSet] = useState(false);

    const serviceAdressen = useServiceAdressen();

    const start = useDeepCompareMemo(() => {
        if (dagPlanning?.status !== DagPlanningRestModelStatusEnum.Gearchiveerd && dagPlanning?.entries) {
            const stop = dagPlanning?.entries?.find((item) => item._type === "STOP" && item.type === DagPlanningStopRestModelTypeEnum.Vertrek) as DagPlanningStopRestModel;
            let startCoordinaat = stop?.coordinaat;

            if (!startCoordinaat) {
                // Wanneer er geen vertrek is, gebruik dan het eerste bezoek als start
                const eersteBezoek = dagPlanning?.entries?.find((item) => item._type === "BEZOEK") as DagPlanningBezoekEntryRestModel;

                if (eersteBezoek) {
                    const serviceAdres = serviceAdressen[eersteBezoek.serviceAdresId];

                    startCoordinaat = serviceAdres?.coordinaten?.find((item) => item.type === CoordinaatRestModelTypeEnum.NavigerenNaar);
                }
            }

            if (startCoordinaat) {
                return {
                    entry: stop,
                    location: {
                        lat: startCoordinaat?.latitude!,
                        lng: startCoordinaat?.longitude!
                    }
                };
            }
        }
    }, [dagPlanning, serviceAdressen?.length]);

    const destination = useDeepCompareMemo(() => {
        if (dagPlanning?.status !== DagPlanningRestModelStatusEnum.Gearchiveerd && dagPlanning?.entries) {
            const stop = dagPlanning?.entries?.find((item) => item._type === "STOP" && item.type === DagPlanningStopRestModelTypeEnum.Bestemming) as DagPlanningStopRestModel;
            let eindCoordinaat = stop?.coordinaat;

            if (!eindCoordinaat) {
                // Wanneer er geen vertrek is, gebruik dan het eerste bezoek als start
                const laatsteBezoek = dagPlanning?.entries?.concat()?.reverse()?.find((item) => item._type === "BEZOEK") as DagPlanningBezoekEntryRestModel;

                if (laatsteBezoek) {
                    const serviceAdres = serviceAdressen[laatsteBezoek.serviceAdresId];
                    eindCoordinaat = serviceAdres?.coordinaten?.find((item) => item.type === CoordinaatRestModelTypeEnum.NavigerenNaar);
                }
            }

            if (eindCoordinaat) {
                return {
                    entry: stop,
                    location: {
                        lat: eindCoordinaat?.latitude!,
                        lng: eindCoordinaat?.longitude!
                    }
                };
            }
        }
    }, [dagPlanning, serviceAdressen?.length]);

    const waypoints = useDeepCompareMemo(() => dagPlanning?.entries?.map((item, index, array) => {
        if (dagPlanning?.status === DagPlanningRestModelStatusEnum.Gearchiveerd) {
            return null;
        }

        let coordinaat: CoordinaatRestModel | undefined = undefined;

        if (item._type === "BEZOEK") {
            coordinaat = serviceAdressen[item.serviceAdresId]?.coordinaten?.find((item) => item.type === CoordinaatRestModelTypeEnum.NavigerenNaar);
        } else if (item._type === "STOP") {
            if (index !== 0 && index !== array.length - 1) {
                coordinaat = item.coordinaat;
            }
        }

        if (!coordinaat) {
            return null;
        }

        return {
            entry: item,
            waypointOptions: {
                location: new google.maps.LatLng(coordinaat.latitude!, coordinaat.longitude!),
                stopover: true
            }
        };
    })
        .filter((item) => item)!
    , [dagPlanning, serviceAdressen?.length]);

    useDeepCompareEffect(() => {
        if (directionsLoading) {
            return;
        }

        if (dagPlanning && directionsCache[dagPlanning.datum]) {
            setDirections(directionsCache[dagPlanning.datum]);
            return;
        }

        if (waypoints && start && destination) {
            setDirectionsLoading(true);

            const directionsService = new google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: start.location,
                    waypoints: waypoints.map((item) => item!.waypointOptions),
                    destination: destination.location,
                    travelMode: google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                        if (dagPlanning) {
                            directionsCache[dagPlanning.datum] = result;
                        }

                        setDirections(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }

                    setDirectionsLoading(false);
                }
            );
        } else {
            setDirections(undefined);
        }
    }, [dagPlanning, waypoints, serviceAdressen?.length, destination, start, directionsLoading]);

    const onMapLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const onMapIdle = useCallback(() => {
        setMapIdle(true);
    }, []);

    useEffect(() => {
        if (mapIdle && directionsBoundsSet) {
            setMapIdleAfterDirectionsBoundSet(true);
        }
    }, [mapIdle, directionsBoundsSet, setMapIdleAfterDirectionsBoundSet]);

    useEffect(() => {
        if (!map || directionsLoading) {
            return;
        }

        if (directions) {
            map.fitBounds(directions.routes[0].bounds);
            setDirectionsBoundsSet(true);
        } else {
            map.setCenter({lat: 50.8547246, lng: 3.3881741});
        }
    }, [directions, directionsLoading, map]);

    const mapOptions: google.maps.MapOptions = useMemo(() => ({
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_RIGHT
        },
        streetViewControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_RIGHT
        }
    }), []);

    const showMarkers = mapIdleAfterDirectionsBoundSet;

    return (
        <GoogleMap
            mapContainerStyle={{width: "100%", height: "40vh"}}
            zoom={10}
            onLoad={onMapLoad}
            onIdle={onMapIdle}
            options={mapOptions}

            onCenterChanged={() => setMapIdle(false)}
            onProjectionChanged={() => setMapIdle(false)}
            onBoundsChanged={() => setMapIdle(false)}
        >
            <>
                {directions && directionsBoundsSet && mapIdleAfterDirectionsBoundSet && (
                    <DirectionsRenderer directions={directions} options={{
                        suppressMarkers: true,
                        preserveViewport: true
                    }}/>
                )}

                {showMarkers && start?.entry && (
                    <OverlayView mapPaneName="overlayMouseTarget" position={start.location}
                                 getPixelPositionOffset={() => ({x: -15, y: -15})}>

                        <VertrekMarker entry={start.entry}
                                       onClick={() => onMarkerClick?.(start.entry as AbstractDagPlanningEntryRestModel)}/>
                    </OverlayView>
                )}

                {showMarkers && destination?.entry && (
                    <OverlayView mapPaneName="overlayMouseTarget" position={destination.location}
                                 getPixelPositionOffset={() => ({x: -15, y: -15})}>

                        <BestemmingMarker entry={destination.entry}
                                          onClick={() => onMarkerClick?.(destination.entry as AbstractDagPlanningEntryRestModel)}/>
                    </OverlayView>
                )}

                {showMarkers && waypoints?.map((item, index) => (
                    <OverlayView key={item!.entry.id} mapPaneName="overlayMouseTarget"
                                 position={item!.waypointOptions.location}
                                 getPixelPositionOffset={() => ({x: -15, y: -15})}>
                        <DagPlanningEntryMarker entry={item?.entry} index={index}
                                                onClick={() => onMarkerClick?.(item!.entry)}/>
                    </OverlayView>
                ))}
            </>
        </GoogleMap>
    );
};
