import React, {useCallback, useMemo} from "react";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
    MDBSpinner,
} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {
    useApplicationStatus,
    useNetworkStatus,
    useSyncingActieveBezoekSessies,
    useSyncingArtikelAfbeeldingen,
    useSyncingArtikelen,
    useWebSocketStatus,
} from "../redux/slices/status/hooks";
import {NetworkStatus} from "../redux/slices/status/types";
import Icon from "@mdi/react";
import {mdiCheck, mdiClose} from "@mdi/js";
import {formatDate, formatUsingIntlDateTimeFormat} from "../utilities/dateUtils";
import {
    useManualSyncToServer,
    useSyncActieveBezoekSessies,
    useSyncArtikelAfbeeldingen,
    useSyncArtikelen,
} from "../utilities/serviceWorkerHooks";
import {useStorageEstimation} from "../utilities/storageHooks";
import {NavLink} from "react-router-dom";
import {ContentContainer} from "../components/ContentContainer";
import {useTranslation} from "../utilities/i18nUtils";
import {Trans} from "react-i18next";
import {Config} from "utilities/config";

export const OfflinebeheerPage: React.FC = () => {
    const {t, language} = useTranslation("algemeen");

    const networkStatus = useNetworkStatus();
    const offlineStatus = useApplicationStatus();
    const webSocketStatus = useWebSocketStatus();

    const storageEstimate = useStorageEstimation();

    const manualSyncToServer = useManualSyncToServer();

    const syncActieveBezoekSessies = useSyncActieveBezoekSessies();

    const syncArtikelen = useSyncArtikelen();
    const syncArtikelAfbeeldingen = useSyncArtikelAfbeeldingen();

    const syncingActieveBezoekSessies = useSyncingActieveBezoekSessies();
    const syncingArtikelen = useSyncingArtikelen();
    const syncingArtikelAfbeeldingen = useSyncingArtikelAfbeeldingen();

    const allSynced =
        offlineStatus.localEventsAmount + offlineStatus.localFilesAmount === 0;

    const actieveBezoekSessieLastSyncTimestampText = useMemo(() => {
        if (offlineStatus.actieveBezoekSessieLastSyncTimestamp) {
            return formatDate(
                offlineStatus.actieveBezoekSessieLastSyncTimestamp,
                "DD-MM-YYYY HH:mm"
            )
        }

        return "onbekend";
    }, [offlineStatus.actieveBezoekSessieLastSyncTimestamp]);

    const periodicSyncLastTimestampText = useMemo(() => {
        if (offlineStatus.periodicSyncLastTimestamp) {
            return formatDate(
                offlineStatus.periodicSyncLastTimestamp,
                "DD-MM-YYYY HH:mm"
            );
        }

        return "onbekend";
    }, [offlineStatus.periodicSyncLastTimestamp]);

    const onSyncClick = useCallback(() => {
        manualSyncToServer();
    }, [manualSyncToServer]);

    const onBezoekenVernieuwenClick = useCallback(() => {
        syncActieveBezoekSessies();
    }, [syncActieveBezoekSessies]);

    const onArtikelenVernieuwenClick = useCallback(() => {
        syncArtikelen(true);
    }, [syncArtikelen]);

    const onArtikelAfbeeldingenLadenClick = useCallback(() => {
        syncArtikelAfbeeldingen();
    }, [syncArtikelAfbeeldingen]);

    const memory: {
        jsHeapSizeLimit: number;
        totalJSHeapSize: number;
        usedJSHeapSize: number;
    } = (window.performance as any)?.["memory"];

    return (
        <GenericPage>
            <Header title={t("OfflineBeheerPage.titel", "Offlinebeheer")} sticky/>

            <ContentContainer>
                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>{t("OfflineBeheerPage.verbinding", "Verbinding")}</MDBCardTitle>

                        <MDBCardText>
                            {[NetworkStatus.ONLINE, NetworkStatus.SLOW].includes(
                                networkStatus
                            ) && (
                                <>
                                    <Icon path={mdiCheck} size={2} className="text-success"/>
                                    <Trans t={t} i18nKey={"OfflineBeheer.je-bent-online"}>
                                        Je bent <strong>online</strong>.</Trans>
                                </>
                            )}
                            {networkStatus === NetworkStatus.OFFLINE && (
                                <>
                                    <Icon path={mdiClose} size={2} className="text-danger"/>
                                    <Trans t={t} i18nKey={"OfflineBeheerPage.je-bent-offline"}>
                                        Je
                                        bent <strong>offline</strong> sinds {{tijdstip: formatUsingIntlDateTimeFormat(offlineStatus.offlineSinceTimestamp, language, Config.DATETIME_FORMAT)}}.
                                    </Trans>
                                    <p>
                                        {t("OfflineBeheerPage.de-server-is-niet-meer-te-bereiken", "De server is niet meer te bereiken. Dit kan liggen aan de internetconnectiviteit van het toestel (mobiel internet, wifi), of de server ondervindt problemen.")}
                                    </p>
                                </>
                            )}
                        </MDBCardText>

                        <MDBCardText>
                            {webSocketStatus === NetworkStatus.ONLINE && (
                                <>
                                    <Icon path={mdiCheck} size={2} className="text-success"/>{" "}
                                    {t("OfflineBeheerPage.directe-verbinding-is-actief",
                                        "Directe verbinding (WebSocket) is actief. Deze directe verbinding zorgt voor live updates.")}
                                </>
                            )}
                            {webSocketStatus === NetworkStatus.OFFLINE && (
                                <>
                                    <Icon path={mdiClose} size={2} className="text-danger"/>
                                    {t("OfflineBeheerPage.directe-verbinding-is-verbroken",
                                        "De directe verbinding (WebSocket) is verbroken. Deze directe verbinding zorgt voor live updates. Updates komen nu dus niet live binnen.")}
                                </>
                            )}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>{t("OfflineBeheerPage.gegevens-op-toestel", "Gegevens op toestel")}</MDBCardTitle>

                        <MDBCardText>
                            <p>{t("OfflineBeheerPage.hier-vind-je-of-je-ingegeven-verslaggegevens-correct-naar-de-server-zijn-doorgestuurd",
                                "Hier vind je of je ingegeven verslaggegevens correct naar de server zijn doorgestuurd. Indien je offline bent, worden deze items tijdelijk op je toestel bewaard.")}
                            </p>

                            <p>
                                {t("OfflineBeheerPage.laatste-succesvolle-synchronisatie",
                                    "Laatste succesvolle synchronisatie met server: {{planningLastSyncTimestampText, datetime}}",
                                    {
                                        planningLastSyncTimestampText: (offlineStatus.planningLastSyncTimestamp ?
                                            new Date(offlineStatus.planningLastSyncTimestamp)
                                            : t("OfflineBeheerPage:laatste-succesvolle-synchronisatie-onbekend", "onbekend")),
                                        formatParams: {
                                            planningLastSyncTimestampText: Config.DATETIME_FORMAT
                                        }
                                    })
                                }
                            </p>

                            {allSynced && (
                                <p>
                                    <Icon path={mdiCheck} size={2} className="text-success"/>{" "}
                                    {t("OfflineBeheerPage.alle-items-zijn-gesynchroniseerd", "Alle items zijn gesynchroniseerd")}
                                </p>
                            )}

                            {!allSynced && (
                                <>
                                    <p>
                                        <Icon path={mdiClose} size={2} className="text-danger"/>{" "}
                                        <Trans t={t}
                                               i18nKey="OfflineBeheerPage.dit-toestel-bevat-x-items-en-y-bestanden-die-nog-niet-gesynchroniseerd-werden">
                                            Dit toestel bevat{" "}
                                            <strong>{{aantalEvents: offlineStatus.localEventsAmount} as any}</strong> items
                                            en{" "}
                                            <strong>{{aantalBestanden: offlineStatus.localFilesAmount} as any}</strong> bestanden
                                            die nog niet gesynchroniseerd werden.
                                        </Trans>
                                    </p>
                                    <MDBBtn type="button" size="lg" onClick={onSyncClick}>
                                        {t("OfflineBeheerPage.gegevens-doorsturen", "Gegevens doorsturen")}
                                    </MDBBtn>
                                </>
                            )}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>{t("OfflineBeheerPage.gegevensversheid.titel", "Gegevensversheid")}</MDBCardTitle>

                        <MDBCardText>
                            <p> {t("OfflineBeheerPage.gegevensversheid.omschrijving",
                                "Deze sectie bevat de tijdstippen wanneer bepaalde informatie laatst van de server werd opgehaald.")}

                            </p>

                            <p>
                                {t("OfflineBeheerPage.gegevensversheid.actieve-bezoeken",
                                    "Actieve bezoeken: {{aantal}}",
                                    {aantal: actieveBezoekSessieLastSyncTimestampText})}
                            </p>

                            <p>
                                {t("OfflineBeheerPage.gegevensversheid.laatste-synchronisatie-tijdstip",
                                    "Automatische synchronisatie (om de 12 uur): {{tijdstip}}",
                                    {tijdstip: periodicSyncLastTimestampText})}
                            </p>

                            <MDBRow className="mb-3 justify-content-around">
                                <MDBCol sm="12" md="4" className="mb-2">
                                    <MDBBtn
                                        type="button"
                                        block
                                        size="lg"
                                        onClick={onBezoekenVernieuwenClick}
                                        disabled={syncingActieveBezoekSessies}
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        {syncingActieveBezoekSessies && (
                                            <MDBSpinner size="sm" className="me-2"/>
                                        )}
                                        {t("OfflineBeheerPage.bezoeken-vernieuwen", "Bezoeken vernieuwen")}
                                    </MDBBtn>
                                </MDBCol>

                                <MDBCol sm="12" md="4" className="mb-2">
                                    <MDBBtn
                                        type="button"
                                        block
                                        size="lg"
                                        onClick={onArtikelenVernieuwenClick}
                                        disabled={syncingArtikelen}
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        {syncingArtikelen && (
                                            <MDBSpinner size="sm" className="me-2"/>
                                        )}
                                        {t("OfflineBeheerPage.artikelen-vernieuwen", "Artikelen vernieuwen")}
                                    </MDBBtn>
                                </MDBCol>

                                <MDBCol sm="12" md="4" className="mb-2">
                                    <MDBBtn
                                        type="button"
                                        block
                                        size="lg"
                                        onClick={onArtikelAfbeeldingenLadenClick}
                                        disabled={syncingArtikelAfbeeldingen}
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        {syncingArtikelAfbeeldingen && (
                                            <MDBSpinner size="sm" className="me-2"/>
                                        )}
                                        {t("OfflineBeheerPage.artikelafbeeldingen-laden", "Artikelafbeeldingen laden")}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>

                            <p>{t("OfflineBeheerPage.aantal-items-in-cache", "Aantal items in cache:")}</p>
                            <MDBListGroup className="mb-3">
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.artikelen", "Artikelen")}
                                    <MDBBadge pill>{offlineStatus.cacheItems.artikelen}</MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.dagplanning", "Dagplanning")}
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.dagPlanning}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.dagplanning-items", "Dagplanning-items")}
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.dagPlanningEntries}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.klanten", "Klanten")}
                                    <MDBBadge pill>{offlineStatus.cacheItems.klanten}</MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.serviceadressen", "Serviceadressen")}
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.serviceAdressen}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.technici", "Technici")}
                                    <MDBBadge pill>{offlineStatus.cacheItems.technici}</MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.toestellen", "Toestellen")}
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.toestellen}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.bestanden", "Bestanden")}
                                    <MDBBadge pill>{offlineStatus.cacheItems.bestanden}</MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <NavLink to="/lokale-gebeurtenissen">
                                        {t("OfflineBeheerPage.gegevensversheid.lokale-gebeurtenissen", "Lokale gebeurtenissen")}
                                    </NavLink>
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.localEvents}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    {t("OfflineBeheerPage.gegevensversheid.gebeurtenissen-vanaf-server", "Gebeurtenissen vanaf server")}
                                    <MDBBadge pill>
                                        {offlineStatus.cacheItems.serverEvents}
                                    </MDBBadge>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <NavLink to="/foutmeldingen">
                                        {t("OfflineBeheerPage.gegevensversheid.foutmeldingen", "Foutmeldingen")}
                                    </NavLink>
                                    <MDBBadge pill>{offlineStatus.cacheItems.errors}</MDBBadge>
                                </MDBListGroupItem>
                            </MDBListGroup>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>{t("OfflineBeheerPage.opslag.titel", "Opslag")}</MDBCardTitle>

                        <MDBCardText>
                            <p>
                                {t("OfflineBeheerPage.opslag.omschrijving",
                                    "Deze sectie toont hoeveel opslag er beschikbaar is voor deze  applicatie en hoeveel er in gebruik is.")}
                            </p>

                            <MDBListGroup className="mb-3">
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <div>{t("OfflineBeheerPage.opslag.beschikbaar", "Beschikbaar:")}</div>
                                    <div>
                                        {((storageEstimate?.quota || 0) / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                    </div>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <div>{t("OfflineBeheerPage.opslag.in-gebruik", "In gebruik:")}</div>
                                    <div>
                                        {((storageEstimate?.usage || 0) / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                    </div>
                                </MDBListGroupItem>
                            </MDBListGroup>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>{t("OfflineBeheerPage.werkgeheugen.titel", "Werkgeheugen")}</MDBCardTitle>

                        <MDBCardText>
                            <MDBListGroup className="mb-3">
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <div>{t("OfflineBeheerPage.werkgeheugen.in-gebruik", "In gebruik:")}</div>
                                    <div>
                                        {((memory?.usedJSHeapSize || 0) / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                    </div>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <div>{t("OfflineBeheerPage.werkgeheugen.totaal-toegewezen", "Totaal toegewezen:")}</div>
                                    <div>
                                        {((memory?.totalJSHeapSize || 0) / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                    </div>
                                </MDBListGroupItem>
                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <div>{t("OfflineBeheerPage.werkgeheugen.limiet", "Limiet:")}</div>
                                    <div>
                                        {((memory?.jsHeapSizeLimit || 0) / 1024 / 1024).toFixed(2)}{" "}
                                        MB
                                    </div>
                                </MDBListGroupItem>
                            </MDBListGroup>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </ContentContainer>
        </GenericPage>
    );
};
